<template>
  <v-container class="faq-list mt-2 mt-sm-10">
    <!-- <Breadcrumb :items="breadcrumbs" /> -->
    <!-- <h1>{{ $t("navbar.faq.title") }}</h1>
    <span>{{ $t("navbar.faq.subtitle") }}</span> -->
    <CategoryTitle :category="category" />
    <div v-if="content != null">
      <v-expansion-panels flat class="faq-list mt-2" accordion>
        <v-expansion-panel
          class="rounded mt-3"
          v-for="item in content"
          :key="item.id"
        >
          <v-expansion-panel-header class="primary lighten-1 white--text">
            <template v-slot:actions>
              <v-icon color="white">$expand</v-icon>
            </template>
            <p class="my-2">
              <strong v-html="item.title.rendered"></strong>
            </p>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-html="item.content.rendered"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-row no-gutters justify="center" class="mt-6">
      <v-btn
        :title="$t('navbar.faq.backBtn')"
        class="back-button"
        large
        color="primary"
        depressed
        to="/"
      >
        {{ $t("navbar.faq.backBtn") }}
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import CMService from "~/service/cmService";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import categoryMixins from "~/mixins/category";
// import Breadcrumb from "@/components/navigation/Breadcrumb";
export default {
  name: "FaqList",
  components: { CategoryTitle },
  mixins: [categoryMixins],
  data() {
    return {
      content: null,
      pageData: null
    };
  },
  jsonld() {
    if (this.content) {
      const items = this.content.map(item => ({
        "@type": "Question",
        name: item.title.rendered,
        acceptedAnswer: {
          "@type": "Answer",
          text: item.content.rendered
        }
      }));
      return {
        "@context": "http://schema.org",
        "@type": "FAQPage",
        mainEntity: items
      };
    }
  },
  mounted() {
    this.fetchFaqList();
  },
  computed: {
    // breadcrumbs() {
    //   let breadCrumbs = [];
    //   breadCrumbs.push({
    //     to: { name: "Home" },
    //     text: "Homepage",
    //     exact: true
    //   });
    //   breadCrumbs.push({
    //     // to: {
    //     //   name: "Category",
    //     //   params: {
    //     //     pathMatch: this.category.slug
    //     //   }
    //     // },
    //     text: this.category.name,
    //     exact: true
    //   });
    //   return breadCrumbs;
    // }
  },
  methods: {
    async fetchFaqList() {
      const res = await CMService.getCustomPostByFilters("faq", [
        {
          filterName: "filter[faq]"
        }
      ]);

      if (res && res.data && res.data.length) {
        this.content = res.data;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.faq-list {
  img.size-full {
    width: 100%;
  }
  .v-expansion-panel-header {
    padding: 16px 28px;
    font-size: 16px;
    &:not(.v-expansion-panel-header--active) {
      border-bottom-left-radius: $border-radius-root;
      border-bottom-right-radius: $border-radius-root;
    }
  }
  ::v-deep .v-expansion-panel-content__wrap {
    padding: 16px 28px;
    border: 1px solid var(--v-primary-lighten1);
    border-top: none !important;
    border-radius: 0 0 $border-radius-root $border-radius-root;
    p {
      margin-bottom: 0;
    }
  }
}
</style>
